@font-face {
	font-family: 'Assistant-Light';
	src: local('Assistant Light'), local('Assistant-Light'),
	url(./Assistant-Light.woff2) format('woff2'),
	url(./Assistant-Light.woff) format('woff'),
	url(./Assistant-Light.ttf) format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Assistant-Regular';
	src: local('Assistant Regular'), local('Assistant-Regular'),
	url(./Assistant-Regular.woff2) format('woff2'),
	url(./Assistant-Regular.woff) format('woff'),
	url(./Assistant-Regular.ttf) format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Assistant-Bold';
	src: local('Assistant Bold'), local('Assistant-Bold'),
	url(./Assistant-Bold.woff2) format('woff2'),
	url(./Assistant-Bold.woff) format('woff'),
	url(./Assistant-Bold.ttf) format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Assistant-ExtraBold';
	src: local('Assistant ExtraBold'), local('Assistant-ExtraBold'),
	url(./Assistant-ExtraBold.woff2) format('woff2'),
	url(./Assistant-ExtraBold.woff) format('woff'),
	url(./Assistant-ExtraBold.ttf) format('truetype');
	font-weight: 800;
	font-style: normal;
}
